// import CounterExample from 'components/counter-example'
// import FetchData from 'components/fetch-data'
import HomePage from './components/home-page'
import Dashboard from './components/dashboard'
import dashboardsection from './components/dashboardsection'
import dashboardcomparative from './components/dashboardcomparative'
import Login from './components/login'
import Logout from './components/logout'
import Template from './components/template'
import BQCode from './components/bqcode'
import Project from './components/project'
import ProjectAmos from './components/projectamos'
import rowdesignationreport from './components/rowdesignationreport'
import bqcodeselection from './components/bqcodeselection'
import ProjectSetup from './components/projectsetup'
import ProjectSS from './components/projectss'
import ProjectBill from './components/projectbill'
import ChangePassword from './components/changepassword'
import ComparativeAnalysis from './components/comparativeanalysis'
import ElementListLibrary from './components/elementlist'
// import groupinglist from 'components/groupinglist'
import checklistsection from './components/checklistsection'
import comparesearch from './components/comparesearch'

import ProjectCA from './components/projectca'
import ProjectCS from './components/projectcs'
import ConsoleUser from './components/consoleuser'

import CQCLibrary from './components/cqclibrary'
import ProjectCQC from './components/projectcqc'

import AddDdt from './components/addddt'
import EditTracker from './components/edittracker'

import BillCompileCode from './components/billcompilecode'
import Amt from './components/amtemplate'
import AmtEdit from './components/amtemplateedit'
import exporturdata from './components/exporturdata'
import cqcrwcsummary from './components/cqcrwcsummary'
import pspsummary from './components/pspsummary'
import projectdescriptionform from './components/projectdescriptionform'
import pspcriteria from './components/pspcriteria'
import majordimensions from './components/majordimensions'
import updatedatabasestructure from './components/updatedatabasestructure'
import independentmeasuretemplate from './components/independentmeasuretemplate'
import independentmeasure from './components/independentmeasure'
import projecthours from './components/projecthours'
import projecthourslist from './components/projecthourslist'
import exportreport from './components/exportreport'
// import salesreport from 'components/salesreport'
import configuration from './components/configuration'
import role from './components/role'
import sectionalcheck from './components/sectionalcheck'
import signoffnrmelement from './components/signoffnrmelement'
import signoffsectionssummary from './components/signoffsectionssummary'
import signoffproject from './components/signoffproject'
import errorlog from './components/errorlog'
import uniquereference from './components/uniquereference'
import universalreport from './components/universalreport'
import checklistsummary from './components/checklistsummary'
import qatimesubform from './components/qatimesubform'
// import qutimechecklist from './components/qutimechecklist'
import rowdesignationch from './components/rowdesignationch'
import pageguidance from './components/pageguidance'
import momlibrary from './components/momlibrary'
import directionslibrary from './components/directionslibrary'
import rfientryform from './components/rfientryform'
import rfireport from './components/rfireport.vue'
import superadmin from './components/superadmin.vue'
import mcversions from './components/mcversions';
import combinebill from './components/combinebill.vue';

import publicroot from './publiccomponents/public-root';
import externalbill from './publiccomponents/externalbill';

export const routes = [
    {name:'access', path: '/', component: HomePage, display: 'Home', style: 'glyphicon glyphicon-home' },

    {name:'projectamos', path: '/projectamos', component: ProjectAmos, display: 'Project AMOS', style: '' },
    {name:'projectamos', path: '/rowdesignationreport', component: rowdesignationreport, display: 'Row Designation Report', style: '' },
    {name:'projectamos', path: '/bqcodeselection', component: bqcodeselection, display: 'BQ_Code Selection Algorithm', style: '' },
    {name:'rowdesignationch', path: '/rowdesignationch', component: rowdesignationch, display: 'Row Designation CH', style: '' },

    {name:'projectsetup', path: '/projectsetup', component: ProjectSetup, display: 'Project Setup', style: '' },
    {name:'projectdescriptionform', path: '/projectdescriptionform', component: projectdescriptionform, display: 'Project Description Form', style: '' },
    {name:'projectdescriptionform', path: '/pspcriteria', component: pspcriteria, display: 'PSP Criteria  ', style: '' },
    {name:'independentmeasuretemplate', path: '/independentmeasuretemplate', component: independentmeasuretemplate, display: 'Independent Measure Template', style: '' },
    {name:'independentmeasure', path: '/independentmeasure', component: independentmeasure, display: 'Independent Measure ', style: '' },
    { name: 'pspsummary', path: '/pspsummary', component: pspsummary, display: 'PSP Summary', style: '' },
    { name: 'projecthours', path: '/projecthourslist', component: projecthourslist, display: 'Project Hours List', style: '' },
    { name: 'projecthours', path: '/projecthours', component: projecthours, display: 'Project Hours', style: '' },
    { name: 'exportreport', path: '/exportreport', component: exportreport, display: 'Export Report', style: '' },

    {name:'project', path: '/projectpage', component: Project, display: 'Project', style: '' },

    {name:'projectbill', path: '/projectbill', component: ProjectBill, display: 'Bill View', style: '' },

    {name:'template', path: '/templatelibrary', component: Template, display: 'Templates Library', style: '' },
    {name:'amtemplate', path: '/amtlibrary', component: Amt, display: 'Automated Templates Library', style: '' },
    {name:'amtemplate', path: '/amtlibraryedit', component: AmtEdit, display: 'Automated Templates Library', style: '' },
    {name:'bqcode', path: '/bqcodelibrary', component: BQCode, display: 'BQ Code Library', style: '' },
    {name:'comparesearch', path: '/comparesearch', component: comparesearch, display: 'Comparative Search', style: '' },
    {name:'elementlist', path: '/elementlist', component: ElementListLibrary, display: 'Element List Library', style: '' },
    {name:'elementlist', path: '/qatimesubform', component: qatimesubform, display: 'Qu Time', style: '' },
    // {name:'Libraries > Grouping List Library', path: '/groupinglist', component: groupinglist, display: 'Grouping List Library', style: '' },
    {name:'cqclibrary', path: '/cqclibrary', component: CQCLibrary, display: 'CQC Library', style: '' },
    {name:'momlibrary', path: '/momlibrary', component: momlibrary, display: 'MoM Library', style: '' },
    {name:'directionslibrary', path: '/directionslibrary', component: directionslibrary, display: 'Directions Library', style: '' },

    {name:'checklistsection', path: '/checklistsection', component: checklistsection, display: 'Checklist-Section', style: '' },
    // {name:'qutimechecklist', path: '/qutimechecklist', component: qutimechecklist, display: 'Qu Time Checklist', style: '' },
    {name:'checklistsummary', path: '/checklistsummary', component: checklistsummary, display: 'Checklist-Summary', style: '' },
    {name:'edittracker', path: '/edittracker', component: EditTracker, display: 'Edit - Tracker', style: '' },
    {name:'projectcs', path: '/projectcs', component: ProjectCS, display: 'Project CS', style: '' },
    {name:'projectcqc', path: '/projectcqc', component: ProjectCQC, display: 'CQC Project Report', style: '' },
    {name:'cqcrwcsummary', path: '/cqcrwcsummary', component: cqcrwcsummary, display: 'CQC / RWC Summary', style: '' },
    {name:'exporturdata', path: '/exporturdata', component: exporturdata, display: 'exporturdata', style: '' },
    {name:'sectionalcheck', path: '/sectionalcheck', component: sectionalcheck, display: 'QA Sectional Check', style: '' },
    {name:'signoffnrmelement', path: '/signoffnrmelement', component: signoffnrmelement, display: 'Sign Off - NRM Element', style: '' },
    {name:'signoffsectionssummary', path: '/signoffsectionssummary', component: signoffsectionssummary, display: 'Part - Sections Summary', style: '' },
    {name:'signoffproject', path: '/signoffproject', component: signoffproject, display: 'Sign Off - Project', style: '' },
    {name:'projectss', path: '/projectss', component: ProjectSS, display: 'Sorting Sheet', style: '' },
    {name:'addddt', path: '/addddt', component: AddDdt, display: 'Add - Ddt', style: '' },
    {name:'universalreport', path: '/universalreport', component: universalreport, display: 'QA Report', style: '' },
    {name:'combinebill', path: '/combinebill', component: combinebill, display: 'Combine Bills', style: '' },

    //{ name: 'Admin > Project Description Form', path: '/projectdescriptionform', component: projectdescriptionform, display: 'Project Description Form', style: '' },
    //{ name: 'Admin > Project Description Form', path: '/pspcriteria', component: pspcriteria, display: 'PSP Criteria  ', style: '' },
    //{ name: 'Admin > Independent Measure Template', path: '/independentmeasuretemplate', component: independentmeasuretemplate, display: 'Independent Measure Template', style: '' },
    //{ name: 'Admin > Independent Measure', path: '/independentmeasure', component: independentmeasure, display: 'Independent Measure ', style: '' },
    //{ name: 'Admin > PSP Summary', path: '/pspsummary', component: pspsummary, display: 'PSP Summary', style: '' },
    //{ name: 'Admin > Project Hours Calculation', path: '/projecthourscalculation', component: projecthourscalculation, display: 'Project Hours Calculation', style: '' },
    //{ name: 'Admin > Project Hours Report', path: '/projecthoursreport', component: projecthoursreport, display: 'Project Hours Report', style: '' },
    //{ name: 'Admin > Sales Report', path: '/salesreport', component: salesreport, display: 'Sales Report', style: '' },

    { name: 'billcompilecode', path: '/billcompilecode', component: BillCompileCode, display: 'Bill Compile Code', style: '' },
    {name:'consoleuser', path: '/consoleuser', component: ConsoleUser, display: 'Console User', style: '' },
    {name:'role', path: '/role', component: role, display: 'Roles', style: '' },
    {name:'changepassword', path: '/changepassword', component: ChangePassword, display: 'Change Password', style: '' },
    {name:'dashboard', path: '/dashboard', component: Dashboard, display: 'Dashboard - Recent', style: ''   },
    {name:'dashboardsection', path: '/dashboardsection', component: dashboardsection, display: 'Dashboard sections', style: ''   },
    // { name:'dashboardcomparative', path: '/dashboardcomparative', component: dashboardcomparative, display: 'Dashboard - Comparatives', style: ''   },
    { name:'dashboardcomparative', path: '/dashboardcomparative', component: dashboardcomparative, display: 'Comparatives Library', style: ''   },
    {name:'access', path: '/logout', component: Logout, display: 'Logout', style: '' },
    { name: 'updatedatabasestructure', path: '/updatedatabasestructure', component: updatedatabasestructure, display: 'Update Database Structure', style: '' },
    { name: 'errorlog', path: '/errorlog', component: errorlog, display: 'Error Log', style: '' },
    { name: 'uniquereference', path: '/uniquereference', component: uniquereference, display: 'Error Log', style: '' },
    { name: 'pageguidance', path: '/pageguidance', component: pageguidance, display: 'Page Guidance', style: '' },
    { name: 'mcversions', path: '/mcversions', component: mcversions, display: 'Mc Versions', style: '' },


    //This componets not using in nav-menu
    {name:'access', path: '/login', component: Login, display: 'Login', style: '', hide: true },
    {name:'', path: '/comparativeanalysis', component: ComparativeAnalysis, display: 'Comparative Analysis', style: '' },
    {name:'', path: '/projectca', component: ProjectCA, display: 'Project CA', style: '' },
	{name:'', path: '/majordimensions', component: majordimensions, display: 'Major Dimensions', style: '' },
    {name:'', path: '/configuration', component: configuration, display: 'configuration', style: '' },
    {name:'rfientryform', path: '/rfientryform', component: rfientryform, display: 'RFI Entry Form', style: '' },
    {name:'rfireport', path: '/rfireport', component: rfireport, display: 'RFI Report', style: '' },
    {name:'superadmin', path: '/superadmin', component: superadmin, display: 'Super Admin', style: '' },
    //{ path: '/projectcurrent', component: ProjectCurrent, display: 'Current Project', style: '' },

    // This components only use for Public pages...
    {
        path: '/public',
        component: publicroot,
        children: [
            { name:'public', path: '/externalbill',component: externalbill, display: 'External bill view', style: '' },
        ]
    }
]
