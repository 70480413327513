<template>
    <div v-if="validuser">
        <el-menu mode="horizontal"
                 background-color="#333333"
                 text-color="#ffffff"
                 active-text-color="#ffffff">

            <el-menu-item index="1">
                <router-link to="/" exact>Home</router-link>
            </el-menu-item>

            <el-submenu index="Setup"
                        text-color="#fff"
                        v-if="canread('projectdescriptionform') || canread('projecthours') || canread('projectsetup')">

                <template slot="title">
                    Setup
                </template>
                <el-menu-item index="projectdescriptionform" v-if="canread('projectdescriptionform')">
                    <router-link to="/projectdescriptionform">Project Description</router-link>
                </el-menu-item>

                <el-menu-item index="projecthourslist" v-if="canread('projecthours')">
                    <router-link class="highlights" to="/projecthourslist">Project Hours</router-link>
                </el-menu-item>

                <el-menu-item index="projectsetup" v-if="canread('projectsetup')">
                    <router-link to="/projectsetup">Project Setup</router-link>
                </el-menu-item>

                <!-- <el-menu-item index="rowdesignationreport" v-if="canread('projectamos')">
                    <router-link to="/rowdesignationreport">Row Designation (RD)</router-link>
                </el-menu-item> -->
                <!-- <el-menu-item index="projectgroupinglist">
                    <router-link to="/">Project Grouping List</router-link>
                </el-menu-item> -->
            </el-submenu>

            <el-menu-item index="projectamos" v-if="canread('projectamos')">
                <router-link to="/projectamos" title="Automated Measurement of Schedules">AMOS</router-link>
            </el-menu-item>

            <el-menu-item index="projectpage" v-if="canread('project')">
                <router-link to="/projectpage">Project</router-link>
            </el-menu-item>

            <el-menu-item index="projectbill" v-if="canread('projectbill')">
                <router-link to="/projectbill">Bill View</router-link>
            </el-menu-item>

            <el-submenu index="library"
                        text-color="#fff"
                        v-if="canread('template') ||
                        canread('amtemplate') ||
                        canread('bqcode') ||
                        canread('comparesearch') ||
                        canread('elementlist') ||
                        canread('cqclibrary') ||
                        canread('momlibrary')||
                        canread('directionslibrary') || 
                        canread('dashboardcomparative')">

                <template slot="title">
                    Libraries
                </template>
                <el-menu-item index="templatelibrary" v-if="canread('template')">
                    <router-link to="/templatelibrary">MC_Templates Library</router-link>
                </el-menu-item>

                <el-menu-item index="amtlibrary" v-if="canread('amtemplate')">
                    <router-link to="/amtlibrary">AM_Templates Library</router-link>
                </el-menu-item>

                <el-menu-item index="6" v-if="canread('bqcode')">
                    <router-link to="/bqcodelibrary">BQ Code Library</router-link>
                </el-menu-item>

                <el-menu-item index="comparesearch" v-if="canread('comparesearch')">
                    <router-link to="/comparesearch">RWC Search Library</router-link>
                </el-menu-item>

                <el-menu-item index="ElementList" v-if="canread('elementlist')">
                    <router-link to="/elementlist">Checklist Library</router-link>
                </el-menu-item>

                <!-- <el-menu-item index="groupinglist" v-if="canread('Libraries > Grouping List Library')">
                    <router-link to="/groupinglist">Grouping List Library</router-link>
                </el-menu-item> -->

                <el-menu-item index="cqclibrary" v-if="canread('cqclibrary')">
                    <router-link to="/cqclibrary">CQC Library</router-link>
                </el-menu-item>

                <el-menu-item index="momlibrary" v-if="canread('momlibrary')">
                    <router-link to="/momlibrary">MoM Library Editor</router-link>
                </el-menu-item>

                <el-menu-item index="directionslibrary" v-if="canread('directionslibrary')">
                    <router-link to="/directionslibrary">Directions Library</router-link>
                </el-menu-item>

                <el-menu-item index="dashboardcomparative" v-if="canread('dashboardcomparative')">
                    <router-link to="/dashboardcomparative">Comparatives Library</router-link>
                </el-menu-item>
            </el-submenu>

            <el-submenu index="qaanalysis"
                        text-color="#fff"
                        v-if="canread('checklistsection') ||
                        canread('checklistsummary') ||
                        canread('edittracker') ||
                        canread('projectcs') ||
                        canread('projectcqc') ||
                        canread('cqcrwcsummary') ||
                        canread('exporturdata') ||
                        canread('sectionalcheck') ||
                        canread('signoffnrmelement') ||
                        canread('signoffsectionssummary') ||
                        canread('signoffproject') ||
                        canread('projectss') ||
                        canread('addddt') ||
                        canread('universalreport') ||
                        canread('combinebill')">

                <template slot="title">
                    QA-Analysis
                </template>
                <el-menu-item index="checklistsection" v-if="canread('checklistsection')">
                    <router-link to="/checklistsection">Checklist - Section</router-link>
                </el-menu-item>
                <!-- <el-menu-item index="qutimechecklist" v-if="canread('qutimechecklist')">
                    <router-link to="/qutimechecklist">Qu Time Checklist</router-link>
                </el-menu-item> -->

                <el-menu-item index="checklistsummary" v-if="canread('checklistsummary')">
                    <router-link to="/checklistsummary">Checklist - Summary</router-link>
                </el-menu-item>

                <el-menu-item index="projectcs" v-if="canread('projectcs')">
                    <router-link to="/projectcs" class="highlights2">RWC Report</router-link>
                </el-menu-item>

                <el-menu-item index="projectcqc" v-if="canread('projectcqc')">
                    <router-link to="/projectcqc" class="highlights2">CQC Report</router-link>
                </el-menu-item>

                <el-menu-item index="exporturdata" v-if="canread('exporturdata')">
                    <router-link to="/exporturdata" class="highlights2">CQC Export</router-link>
                </el-menu-item>

                <el-menu-item index="cqcrwcsummary" v-if="canread('cqcrwcsummary')">
                    <router-link to="/cqcrwcsummary" class="highlights2">CQC / RWC Summary</router-link>
                </el-menu-item>

                <!-- <el-menu-item index="majordimensions">
                    <router-link to="/majordimensions">Major Dimensions</router-link>
                </el-menu-item> -->

                <el-menu-item index="sectionalcheck" v-if="canread('sectionalcheck')">
                    <router-link to="/sectionalcheck">External Check / QA</router-link>
                </el-menu-item>

                <el-menu-item index="signoffnrmelement" v-if="canread('signoffnrmelement')">
                    <router-link to="/signoffnrmelement" class="highlights2">Sign Off - Sections</router-link>
                </el-menu-item>

                <el-menu-item index="signoffsectionssummary" v-if="canread('signoffsectionssummary')">
                    <router-link to="/signoffsectionssummary" class="highlights2">Sign Off - Parts</router-link>
                </el-menu-item>

                <el-menu-item index="signoffproject" v-if="canread('signoffproject')">
                    <router-link to="/signoffproject" class="highlights2">Sign Off - Project</router-link>
                </el-menu-item>

                <el-menu-item index="sortingsheet" v-if="canread('projectss')">
                    <router-link to="/projectss">Sorting Sheet</router-link>
                </el-menu-item>

                <el-menu-item index="edittracker" v-if="canread('edittracker')">
                    <router-link to="/edittracker">Edit Tracker</router-link>
                </el-menu-item>

                <el-menu-item index="addddt" v-if="canread('addddt')">
                    <router-link to="/addddt">Add &amp; Ddt</router-link>
                </el-menu-item>

                <el-menu-item index="universalreport" v-if="canread('universalreport')">
                    <router-link to="/universalreport" class="highlights3">QA Report</router-link>
                </el-menu-item>

                <el-menu-item index="combinebill" v-if="canread('combinebill')">
                    <router-link to="/combinebill">Combine Bills</router-link>
                </el-menu-item>
            </el-submenu>

            <el-submenu v-if="validuser" index="7" text-color="#fff">

                <template slot="title">
                    Admin
                </template>
                <el-menu-item index="billcompilecode" v-if="canread('billcompilecode')">
                    <router-link to="/billcompilecode">NRM1 Component Code</router-link>
                </el-menu-item>

                <el-menu-item index="consoleuser" v-if="canread('consoleuser')">
                    <router-link class="highlights" to="/consoleuser">Access Permissions</router-link>
                </el-menu-item>

                <el-menu-item index="mcversions" v-if="canread('mcversions')">
                    <router-link  to="/mcversions">MC Versions</router-link>
                </el-menu-item>

                <el-menu-item index="consoleuser" v-if="canread('role')">
                    <router-link class="highlights" to="/role">Role</router-link>
                </el-menu-item>

                <el-menu-item index="consoleuser" v-if="canread('updatedatabasestructure')">
                    <router-link class="highlights" to="/updatedatabasestructure">Update Database Structure</router-link>
                </el-menu-item>
                <el-menu-item index="superadmin" v-if="canread('superadmin')">
                    <router-link class="highlights" to="/superadmin">Super Admin</router-link>
                </el-menu-item>

                <el-menu-item index="consoleuser" v-if="canread('uniquereference')">
                    <router-link class="highlights" to="/uniquereference">Unique Reference(UR)</router-link>
                </el-menu-item>

                <el-menu-item index="consoleuser" v-if="canread('pageguidance')">
                    <router-link class="highlights" to="/pageguidance">Training Popup</router-link>
                </el-menu-item>

                <el-menu-item index="consoleuser" v-if="canread('errorlog')">
                    <router-link class="highlights" to="/errorlog">Error Log</router-link>
                </el-menu-item>

                <el-menu-item index="consoleuser" v-if="validuser || canread('changepassword')">
                    <router-link to="/changepassword">Change Password</router-link>
                </el-menu-item>

                <el-menu-item index="7-1" v-if="canread('dashboard')">
                    <router-link to="/dashboard">Dashboard - Recent</router-link>
                </el-menu-item>

                <el-menu-item index="dashboardsection" v-if="canread('dashboardsection')">
                    <router-link to="/dashboardsection">Dashboard - Sections</router-link>
                </el-menu-item>
                
                <!-- <el-menu-item index="dashboardcomparative" v-if="canread('dashboardcomparative')">
                    <router-link to="/dashboardcomparative">Dashboard - Comparatives</router-link>
                </el-menu-item> -->

                <el-menu-item v-if="validuser" index="7-7">
                    <router-link to="/logout">Logout</router-link>
                </el-menu-item>
                <!--<el-menu-item index="configuration" v-if="user.role=='A'">
                        <router-link  to="/configuration">Configuration</router-link>
                    </el-menu-item>
                    <el-menu-item index="comparativeanalysis">
                        <router-link to="/comparativeanalysis">Comparative Analysis Old</router-link>
                    </el-menu-item>
                    <el-menu-item index="projectca">
                        <router-link to="/projectca">Project Comparative Analysis Old</router-link>
                    </el-menu-item>-->
            </el-submenu>

            <el-submenu index="support"
                        text-color="#fff" v-if="
                        canread('mcuserguide')  ||
                        canread('rfientryform')">
                <template slot="title">
                    Comms
                </template>
                <el-menu-item index="mcuserguide" v-if="canread('mcuserguide')">
                    <a target="_blank" href="https://docs.google.com/document/d/1Dt0wVZNoJ8lEPIDYyaxPXOIskwLpGyPa5simK1QnpOk/edit#heading=h.gjdgxs">MC User Guide</a>
                </el-menu-item>

                <!-- <el-menu-item index="helphub" v-if="canread('helphub')">
                    <a target="_blank" href="https://docs.google.com/document/d/1E_aVLn4LQXPruaahf4yOB77Tib-lD0h9U_UXYLBsUec64/edit">Help hub</a>
                </el-menu-item> -->

                <!-- <el-menu-item index="communityforum" v-if="canread('communityforum')">
                    <a target="_blank" href="https://forum.tsaconsole.co.uk/">Community Forum</a>
                </el-menu-item> -->

                <el-menu-item index="rfientryform" v-if="canread('rfientryform')">
                    <router-link to="/rfientryform">RFI Entry Form</router-link>
                </el-menu-item>
                <el-menu-item index="rfireport" >
                    <router-link to="/rfireport">RFI Reporting / Uploading</router-link>
                </el-menu-item>
            </el-submenu>

            <!-- <el-menu-item index="1"> -->
            <el-tooltip content="Click for guidance" placement="top">
                <b @click="getGuidancedata" class="helpiconstyle">i</b>
            </el-tooltip>
                <!-- <el-button icon="el-icon-info" class="pageguidehelpbtnstyle"></el-button> -->
            <!-- </el-menu-item> -->
        </el-menu>

        <el-dialog v-if="showerror" :title="errortitle"
                   :visible.sync="showerror"
                   :modal-append-to-body="false"
                   :append-to-body="true"
                   width="80%">
            <span v-html="errormessage" />
        </el-dialog>

        <customgraphicdialogue :guidancedialogue="guidancedialogue"></customgraphicdialogue>
        <!-- <directionsdialogue :option="directionsdialogue"></directionsdialogue> -->
    </div>
</template>
<script>
    import { routes } from '../routes';
    import customgraphicdialogue from "./customgraphicdialogue.vue";
    // import directionsdialogue from "./directionsdialogue.vue";
    export default {
        props: {},
        data() {
            return {
                routes,
                collapsed: true,
                token: null,
                validuser: false,
                showerror: false,
                errormessage: "",
                errortitle: "",
                user: {},
                scrollx: 0,
                scrolly: 0,
                guidancedialogue: { visible: false},
            }
        },
        computed: {
            canread() {
                return function (name) {
                    if (this.user.id != undefined) {
                        if (name == "updatedatabasestructure" || name == "errorlog" || name == "superadmin") {
                            return this.user.superadmin == true ? true : false;
                        }
                        if (this.user.role == "A") {
                            return true;
                        }
                        var role = this.user.userrole.rolepermissionpages.find(x => x.p.name === name && x.rp.permission != 0);
                        if (role) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }
        },
        methods: {
        //     drag_start(event) {
        //     var style = window.getComputedStyle(event.target, null);
        //     event.dataTransfer.setData(
        //         "text/plain",
        //         parseInt(style.getPropertyValue("left"), 10) -
        //         event.clientX +
        //         "," +
        //         (parseInt(style.getPropertyValue("top"), 10) - event.clientY)
        //     );
        // },
        // drag_over(event) {
        //     event.preventDefault();
        //     return false;
        // },
        // drop(event) {
        //     // console.log(this.$refs['DirectionsBackground'+this.myoption.id].style.bottom);
        //     var offset = event.dataTransfer.getData("text/plain").split(",");
        //     this.$refs[this.myref].style.left =
        //         event.clientX + parseInt(offset[0], 10) + "px";
        //     this.$refs[this.myref].style.top =
        //         event.clientY + parseInt(offset[1], 10) + "px";
        //         // console.log(this.$refs['DirectionsBackground'+this.myoption.id].getBoundingClientRect());
        //     event.preventDefault();

        //     return false;
        // },
      
        // makeDraggable() {
        //     for (let index = 0; index < 4; index++) {
        //         var dm = this.$refs['mybox' + index];
        //         if (dm[0]) {
        //             dm[0].addEventListener("dragstart", this.drag_start, false);
        //             document.body.addEventListener("dragover", this.drag_over, false);
        //             document.body.addEventListener("drop", this.drop, false);
        //         }    
                
        //     }
            
        // },
            toggleCollapsed: function (event) {
                this.collapsed = !this.collapsed;
            },
            updatemenubar: function () {
                var role = this.user.role;
                this.routes.forEach((route, index) => {
                    if (route.path == "/dashboard") {
                        route.hide = role == 'A' ? null : true;
                        //this.$set(this.routes, index, route); //to make property reactive
                    }
                });
            },
            errorclose: function (done) {
                this.$bus.$emit('errorclose', false);
                done()
            },
            getuser: function () {
                this.$http.post("account/getuserdetails")
                    .then(response => {
                        //var user = JSON.parse(response.data);
                        var user = response.data;
                        this.user = user;
                        this.$store.state.user = user;
                        this.$bus.$emit('setuser', user);
                        this.updatemenubar();
                        this.validuser = true;
                        if (this.validuser) {
                            // this.getsections();
                        }
                        this.$http.defaults.headers.common['Authorization'] = response.headers.authorization;
                        if (this.$route.path == "/") {
                            //this.$router.push('/dashboard')
                        }
                    })
                    .catch(error => {
                        if (!this.validuser) {
                            if (this.$route.path != "/login") {
                                this.$router.push('/login')
                            }
                        }
                    });
            },

            getGuidancedata: function() {
                var customParam = { showguidancedialog: true };
                var routeWithParams = Object.assign({}, this.$route, { params: customParam });
                this.$bus.$emit('userguidegotit', null, routeWithParams,null);
            },

            // getsections: function () {
            //     this.$http.post('project/GetSectionsProjectCreate')
            //         .then(response => {
            //             this.$store.state.sections = response.data;
            //         })
            //         .catch(err => {
            //             this.$bus.$emit('error', err);
            //             this.$bus.$emit('logout', null);
            //         });
            // }
        },

        components: {
            customgraphicdialogue,
        },

        created: function () {
            // this.$nextTick(this.makeDraggable);
            if (this.$store.state.user.id != undefined) {
                this.validuser = true;
                this.user = this.$store.state.user;
                this.$router.push('/login')
            } else {
                this.validuser = false;
            }
            // this.token = localStorage.getItem('token');
            // if (this.token != null) {
            //     this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.token
            //     if(this.$store.state.user.id != undefined){
            //         // this.getuser();
            //         this.$router.push('/login')
            //     }else{
            //            this.updatemenubar();
            //             this.validuser = true;
            //             if (this.validuser) {
            //                 this.getsections();
            //             }
            //     }
            // } else {
            //     if (this.$route.path != "/login") {
            //         this.$router.push('/login')
            //     }
            // }
            this.$bus.$on('setuser', (user) => {
                if (user.id != undefined) {
                    this.user = user;
                    this.validuser = true;
                    if (this.$store.state.sections.length <= 0) {
                        this.getsections();
                    }

                    if (this.$store.state.pages.length == 0) {
                        this.getPages(); 
                    }
                }
            });
            this.$bus.$on('login', (token) => {
                this.token = token;
                this.validuser = true;
                localStorage.setItem('token', token);
                this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
                this.$router.push('/') //dashboard
                this.getuser();
            });
            this.$bus.$on('logout', (token) => {
                this.token = token;
                this.validuser = false;
                this.user = {};
                localStorage.removeItem('token');
                this.$router.push('/login') //home
                delete this.$http.defaults.headers.common['Authorization'];
            });

            
            // this.$bus.$on('userguidegotit', (currentpage, currentrouter,pagecellname) => {
            //     if (!currentpage) {
            //         currentpage = this.$store.state.pages.find(pageitem => pageitem.p.name == currentrouter.name );
            //     }
            //     this.guidancedialogue.visible = false;
            //     if (currentpage && currentpage.p) {
            //         this.getUserGuideReminder(currentpage.p.id).then((guidancedata) => {
            //             this.guidancedialogue.pageid = currentpage.p.id;
            //             if (guidancedata.length > 0) {
            //                 this.$bus.$emit('getUserGuidanceReminder', currentpage.p.id);
            //             } 
            //             else {
            //                 this.guidancedialogue.visible = false;
            //             }
            //         });
            //     }
            // });

            this.$bus.$on('error', (error) => {
                // if (err.response != null) // this is web api error
                // {
                //     console.log(err.response.data);
                //     this.errortitle = err.response.status + ' ' + err.response.statusText;
                //     this.errormessage = err.response.data;

                //     if (err.response.status == 400) { //bad request is for custom error so donot show header
                //         this.errortitle = "Validation Error";
                //     }

                //     this.showerror = true;
                //     this.$bus.$emit('errorclose', true);
                //     if (err.response.status == 400) {
                //         //this.$bus.$emit('logout', null);
                //         //just in case they can duplicate the tab and submit page again
                //         this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                //     }
                // }
                // else { //javascript error
                //     this.errortitle = err.message;
                //     this.errormessage = err.stack;
                //     this.showerror = true;
                // }
                if (error.response != null) {
                    if (error.response.status == 401) {
                        this.$router.push('/login'); //LOGIN
                    }
                    else {
                        var style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>';
                        if (typeof (error.response.data) == "object" && error.response.request.responseType == 'blob') {
                            var reader = new FileReader();
                            const seft = this;
                            reader.onload = function () {
                                seft.$alert(reader.result + style, error.message, {
                                    dangerouslyUseHTMLString: true
                                });
                            };
                            reader.readAsText(error.response.data);
                        }
                        else if (typeof (error.response.data) == "object") {
                            var json = error.response.data;
                            this.$message({
                                showClose: true,
                                message: json.message,
                                type: 'error'
                            });
                        }
                        else {
                            this.$alert(error.response.data + style, error.message, {
                                dangerouslyUseHTMLString: true
                            });
                        }
                    }
                } else {
                    this.$alert(error.message, "Error");
                }
            });

            //save scroll
            this.$bus.$on('savescroll', () => {
                this.scrollx = window.scrollX;
                this.scrolly = window.scrollY;
            });
            this.$bus.$on('setscroll', () => {
                if (this.scrollx || this.scrolly) {
                    this.$nextTick(() => {
                        window.scrollTo(this.scrollx, this.scrolly);
                    });
                }
            });
        }
    }
</script>
<style>

    .slide-enter-active, .slide-leave-active {
        transition: max-height .35s
    }

    .slide-enter, .slide-leave-to {
        max-height: 0px;
    }

    .slide-enter-to, .slide-leave {
        max-height: 20em;
    }

    .el-menu-item a {
        color: #ffffff;
        display: block;
        height: 100%;
        width: 100%;
    }

    .highlights {
        color: #ffd800 !important;
    }

    .highlights2 {
        color: #00ffff !important;
    }

    .highlights3 {
        color: #00f32b !important;
    }

    .el-menu--horizontal > .el-menu-item {
        height: 40px !important;
        line-height: 40px !important;
    }

    .el-menu--horizontal > .el-submenu .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
    }
</style>
